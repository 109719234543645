export default {
  // ? ================
  // ? ==== Layout ====
  // ? ================

  navigation: {
    '.container': {
      width: '100%',
      overflow: 'hidden',
      position: 'static',
      backgroundColor: 'white',
      position: 'static',
      borderBottom: '1px solid lightgrey',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      color: 'text',
      justifyContent: 'flex-start',
      '.smallNavMenu': {
        color: 'text'
      }
    },
    '.logoLocationContainer': {
      position: 'static',
      mr: 'auto',
      '.logo, .logoScrolled': {
        img: {
          maxHeight: ['45px', '50px', '55px', '55px'],
          filter: 'unset'
        }
      }
    },
    '.smallNavMenu': {
      '.navItem': {
        a: {
          fontSize: '0.8rem'
        }
      }
    },
    '.navMenuLogo': {
      mb: '3rem'
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: '#f8f8f8',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },

    '.sectionHeading': {
      color: 'primary',
      fontWeight: '500',
      borderColor: 'secondary'
    },
    '.multiButtonContainer': {
      a: {
        display: 'flex',
        justifyContent: 'initial',
        fontSize: '1rem',
        textTransform: 'uppercase'
      }
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    }
    // '.multiButtonContainer': {
    //   border: 'none',
    //   a: {
    //     border: 'none!important',
    //   },
    // },
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ==================
  // ? === Reuseables ===
  // ? ==================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    color: 'primary',
    fontWeight: '300',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    width: 'fit-content'
  },
  subtitle: {
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    fontFamily: 'display',
    fontWeight: '300'
  },

  sideBySide: {
    '.lazyload-wrapper': {
      p: '1rem'
    },
    '.content': {
      py: '2rem'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ================
  // ? === Homepage ===
  // ? ================

  homepageHero: {
    backgroundColor: 'backgroundSecondary',
    backgroundImage: "url('https://www.transparenttextures.com/patterns/sandpaper.png')",
    // url('https://res.cloudinary.com/gonation/image/upload/v1676923628/sites/the-silk-touch/rose-hero.jpg')
    backgroundRepeat: 'repeat, no-repeat',
    backgroundPosition: ['center, right 35%', 'center, right 20%', 'center, right bottom'],
    backgroundSize: ['50px, 50%', '', '50px, 30%'],
    padding: '5vh 5vw',
    height: ['', '', '70vh'],
    marginBottom: ['8rem', '', '30vh'],
    '.lazyload-wrapper': {
      position: 'relative',
      height: '125%',
      borderRadius: '1000px 1000px 0px 0px',
      overflow: 'hidden',
      width: ['', '', '40%'],
      border: 'solid 20px #f5fffb',
      boxShadow: '0px 20px 15px 9px #f4f6f4'
    },
    '.content': {
      padding: ['', '', '5% 0% 5% 10%'],
      width: ['', '', '60%']
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      mt: '1rem',
      mb: '2rem',
      borderTop: '1px solid',
      borderColor: 'secondary',
      pt: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    marginBottom: ['2rem', '', '-15vh'],
    '.content': {
      position: 'relative',
      top: '-15vh',
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem 2.5rem 10vw', '2.5rem 4.5rem 2.5rem 15vw'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        color: 'text',
        fontWeight: 'bold',
        textTransform: 'initial',
        order: '1',
        fontFamily: 'body',
        fontSize: '0.85rem',
        textTransform: 'uppercase'
      },
      '.text': {
        order: '3'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      position: 'relative',
      top: '-30vh',
      img: {
        height: '90vh',
        objectFit: 'contain'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageBoxes: {
    padding: '0rem 5vw',
    '.contentBoxes': {
      flexDirection: ['column', '', 'row']
    },
    '.box': {
      width: ['100%', '100%', '33.33%'],
      flexGrow: '1',
      flex: '1'
    },
    '.image': {
      flex: '1',
      objectFit: 'cover',
      borderRadius: '200px 200px 50px 50px',
      border: 'solid 19px #f4f9f9',
      transition: 'all ease-in-out 1s',
      objectPosition: '50% 50%',
      ':hover': {
        filter: 'brightness(0.9)',
        objectPosition: '60% 60%',
        opacity: '0.8'
      }
    },
    '.title': {
      backgroundColor: 'backgroundSecondary',
      padding: '2rem',
      textAlign: 'center',
      margin: 'auto',
      color: 'primary',
      width: 'fit-content',
      transform: 'translateY(-65%)',
      borderRadius: '10px'
    },
    '.subtitle': {
      transform: 'translateY(-100%)',
      textAlign: 'center'
    },
    '.text': {
      transform: 'translateY(-100%)',
      textAlign: 'center'
    }
  },

  homepageImageSection: {},

  homepageTestimonials: {
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1677856954/sites/the-silk-touch/reviews.png), url(https://res.cloudinary.com/gonation/image/upload/a_180/v1677856954/sites/the-silk-touch/reviews.png)',
    backgroundSize: '40%',
    backgroundPosition: 'bottom right, top left',
    padding: ['3.5rem 1rem 2rem', '', '23vh 3rem 20vh'],
    maxWidth: 'unset',
    marginBottom: 0,
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      mt: '1rem',
      fontSize: ['1rem', '1.2rem', '1.3rem', '1.4rem'],
      fontWeight: 'bold'
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '600px',
      margin: '0rem auto'
    }
  },

  // ? =============
  // ? === About ===
  // ? =============

  aboutSection: {
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ======================
  // ? === Pricing / Menu ===
  // ? ======================

  pricing: {
    maxWidth: 'unset',
    '.menuSectionTitle': {
      display: 'none'
    },
    '.allInContainerWrapper': {
      maxWidth: '1200px',
      margin: '0 auto'
    },
    '.menuItemInnerContainer': {
      flexDirection: ['column', '', 'row']
    },
    '.menuItemImageContainer': {
      maxWidth: ['unset', '', ''],
      minWidth: ['100%', '', '300px', '400px'],
      maxHeight: 'unset',
      height: ['300px', '', '', '400px'],
      width: ['100%', '', '300px', '400px']
    },
    '.menuItemName': {
      fontSize: '1.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      width: 'fit-content',
      fontFamily: 'heading'
    },
    '.menuItemContentContainer': {
      padding: ['', '', '1rem'],
      width: 'inherit'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', 'calc(50% - 2rem)', '', 'calc(33.3% - 2rem)'],
      ':hover': {
        '.cellImage': {
          transform: 'translate(-50%,-50%) scale(1.1)'
        }
      }
    },
    '.cellImageContainer': {
      overflow: 'hidden',
      '.imageFill': {
        paddingBottom: '75%'
      }
    },
    '.menuCell, .cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s'
    },
    '.menuCell': {
      border: 'solid lightgrey 1px',
      boxShadow: '1px 1px 20px 1px lightgrey'
    },
    '.cellName': {
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      fontFamily: 'heading'
    },
    '.menuItemContainerImgActive': {
      width: ['100%', '100%', '100%', '100%']
    },
    '.backToMenuBtn': {
      variant: 'buttons.primary',
      marginBottom: '3rem'
    }
  },

  // ? ===============
  // ? === Gallery ===
  // ? ===============

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumName': {
      display: 'none'
    },
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ===============
  // ? === Contact ===
  // ? ===============

  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
        // borderRadius: '0px',
      }
    },

    locationMap: {
      order: '3',
      h3: {
        textTransform: 'uppercase'
      },

      '.dayofWeekText': {
        margin: '0 auto 0 0'
      }
    }
  },

  // ? ===============
  // ? === Rentals ===
  // ? ===============

  rentals: {}
}
